import {
  scriptImported,
  interstitialAdLoaded,
  stickyFooterRefreshed,
  CREATE_AD,
  DESTROY_AD,
  adCreated,
  adDestroyed,
  SCRIPT_IMPORTED,
  trinityScriptImported,
  trinityScriptRemoved,
  REMOVE_TRINITY_SCRIPT,
  IMPORT_TRINITY_SCRIPT,
} from '../store/modules/adnimation'
import { ROUTE_FOUND } from '../store/modules/router'
import { combineEpics, ofType } from 'redux-observable'
import { map, mergeMap, tap, take, filter, withLatestFrom } from 'rxjs'
import { logObservableError } from '../utilities/logs'
import { TCDATA_READY } from '../store/modules/consentManagement'
import { includes, isNil } from 'ramda'

// importScriptEpic :: Epic -> Observable Action.SCRIPT_IMPORTED
export const importScriptEpic = (action$, _, { document, logger }) =>
  action$.pipe(
    ofType(TCDATA_READY),
    take(1),
    mergeMap(() => new Promise(resolve => {
      const script = document.createElement('script')
      script.async = true
      script.src = 'https://adncdn.net/5eyDSZ2YeO'
      script.onload = () => resolve()

      document.querySelector('head').appendChild(script)
    })),
    map(scriptImported),
    logObservableError('adnimation :: importScriptEpic', logger),
  )

// loadInterstialAdEpic :: Epic -> Observable Action.INTERSTITIAL_AD_LOADED
export const loadInterstialAdEpic = (action$, _, { logger, window }) =>
  action$.pipe(
    ofType(SCRIPT_IMPORTED),
    take(1),
    tap(() => {
      window.googletag = window.googletag || { cmd: [] }
      window.googletag.cmd = Array.isArray(window.googletag.cmd) ? window.googletag.cmd : []
      window.googletag.cmd.push(function () {
        var slot = window.googletag.defineOutOfPageSlot(
          '/22034362842/i24Web/i24Web_Interstitial',
          window.googletag.enums.OutOfPageFormat.INTERSTITIAL,
        )

        if (slot) { 
          slot.addService(window.googletag.pubads()).setConfig({
            interstitial: {
              triggers: {
                navBar: true,
                unhideWindow: true,
              },
            },
          })

          window.googletag.enableServices()
          window.googletag.display(slot)
        }
      })
    }),
    map(interstitialAdLoaded),
    logObservableError('adnimation :: loadInterstialAdEpic', logger),
  )

 // createAdEpic :: Epic -> Observable Action.AD_CREATED
 export const createAdEpic = (action$, _, { logger, adn }) =>
   action$.pipe(
     ofType(CREATE_AD),
     filter(() => !isNil(adn())),
     tap(({ id }) => adn().createAd(id)),
     map(({ id }) => adCreated(id)),
     logObservableError('adnimation :: createAdEpic', logger),
   )

 // destroyAdEpic :: Epic -> Observable Action.AD_DESTROYED
 export const destroyAdEpic = (action$, _, { logger, adn }) =>
  action$.pipe(
    ofType(DESTROY_AD),
    filter(() => !isNil(adn())),
    tap(({ id }) => adn().destroyAd(id)),
    map(({ id }) => adDestroyed(id)),
    logObservableError('adnimation :: destroyAdEpic', logger),
  )

// refreshStickyFooterEpic :: Epic -> Action.STICKY_FOOTER_REFRESHED
export const refreshStickyFooterEpic = (action$, state$, { logger, adn }) =>
  action$.pipe(
    ofType(ROUTE_FOUND),
    withLatestFrom(state$),
    filter(([ _, state ]) =>
      !isNil(adn())
      && state.adnimation.scriptImported
      && state.router.matchesCounter > 1,
    ),
    tap(() => {
      const id = '/22034362842/i24Web/Sticky_Footer'

      adn().destroyAd(id)
      adn().createStickyBanner()
      adn().createAd(id)
    }),
    map(stickyFooterRefreshed),
    logObservableError('adnimation :: refreshStickyFooterEpic', logger),
  )

// importTrinityScriptEpic :: Epic -> Observable Action.TRINITY_SCRIPT_IMPORTED
export const importTrinityScriptEpic = (action$, state$, { logger, document }) =>
  action$.pipe(
    ofType(IMPORT_TRINITY_SCRIPT),
    withLatestFrom(state$),
    filter(([ _, state]) =>
      includes(
        state.router.activeRoute.name,
        ['articleAr', 'articleEn', 'articleFr', 'articleHe'],
      )
      && !state.adnimation.trinityScriptImported,
    ),
    mergeMap(([ _, state]) => new Promise(resolve => {
      const script = document.createElement('script')
      script.setAttribute('fetchpriority', 'high')
      script.setAttribute('charset', 'UTF-8')
      const scriptURL = new URL('https://trinitymedia.ai/player/trinity/2900021087/')
      scriptURL.searchParams.set('pageURL', state.router.activeRoute.href)
      script.src = scriptURL.toString()
      script.onload = () => resolve()
      script.id = 'adnimation-trinity-script'
      document.querySelector('head').appendChild(script)
    })),
    filter(() => null !== document.getElementById('adnimation-trinity-script')),
    map(trinityScriptImported),
    logObservableError('adnimation :: importTrinityScriptEpic', logger),
  )

// removeTrinityScriptEpic :: Epic -> Observable Action.TRINITY_SCRIPT_REMOVED
export const removeTrinityScriptEpic = (action$, state$, { logger, document }) =>
  action$.pipe(
    ofType(REMOVE_TRINITY_SCRIPT),
    withLatestFrom(state$),
    filter(([ _, state]) =>
      !includes(
        state.router.activeRoute.name,
        ['articleAr', 'articleEn', 'articleFr', 'articleHe'],
      )
      && state.adnimation.trinityScriptImported,
    ),
    tap(() => {
      const script = document.getElementById('adnimation-trinity-script')
      if (script) script.remove()
    }),
    filter(() => null === document.getElementById('adnimation-trinity-script')),
    map(trinityScriptRemoved),
    logObservableError('adnimation :: removeTrinityScriptEpic', logger),
  )

export default combineEpics(
  importScriptEpic,
  loadInterstialAdEpic,
  createAdEpic,
  destroyAdEpic,
  importTrinityScriptEpic,
  removeTrinityScriptEpic,
  refreshStickyFooterEpic,
)
